<template>
  <div class="accomplishment-carousel glide">
    <div class="glide__track" data-glide-el="track">
      <div class="glide__slides">
        <div v-for="i in total" :key="i" class="glide__slide">
          <div class="accomplishment-slide">
            <slot :name="`slide_${i}`"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';

export default {
  name: 'AccomplishmentCarousel',
  props: {
    totalItems: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overideNumber: null,
      glide: null,
      peekValue: { before: 0, after: 0 },
    };
  },
  computed: {
    total() {
      return parseInt(this.totalItems);
    },
  },
  mounted() {
    this.glide = new Glide('.accomplishment-carousel', {
      type: 'carousel',
      autoplay: 3000,
      gap: 8,
      perView: 3,
      breakpoints: {
        768: {
          perView: 3,
        },
        640: {
          perView: 2.2,
        },
        500: {
          perView: 1.3,
        },
      },
    }).mount();
  },
  methods: {
    overide(i) {
      if (this.overideNumber === null) {
        this.overideNumber = i;
        const _this = this;
        window.setTimeout(() => {
          _this.overideNumber = null;
        }, 500);
      }
    },
  },
};

const cards = document.querySelectorAll('.accomplishment-slide');
const rotations = ['rotate-left', 'rotate-right', 'rotate-none'];

cards.forEach(card => {
  const randomRotation = rotations[Math.floor(Math.random() * rotations.length)];
  card.classList.add(randomRotation);
});
</script>
