<template>
  <div class="news-carousel glide" :class="{ 'single-glide': total < 2 }">
    <div class="glide__arrows" data-glide-el="controls">
      <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#004E90"
            d="M5.5688 12.5312 5.0375 12l.5313-.5313 4.8-4.8.5312-.5313 1.0594 1.0594-.5313.5313L7.9094 11.25H19v1.5H7.9094l3.5219 3.5187.5312.5313-1.0594 1.0593-.5312-.5312-4.8031-4.7969Z"
          />
        </svg>
      </button>
      <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#004E90"
            d="M18.4312 12.5312 18.9625 12l-.5313-.5313-4.7999-4.8-.5313-.5313-1.0594 1.0594.5313.5313 3.5187 3.5219H5v1.5h11.0875l-3.5188 3.5187-.5312.5313 1.0594 1.0593.5312-.5312 4.8031-4.7969Z"
          />
        </svg>
      </button>
    </div>
    <div class="glide__track" data-glide-el="track">
      <div class="glide__slides">
        <div v-for="i in total" :key="i" class="glide__slide">
          <slot :name="`slide_${i}`"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';

export default {
  name: 'NewsCarousel',
  props: {
    totalItems: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overideNumber: null,
      glide: null,
      peekValue: { before: 0, after: 0 },
    };
  },
  computed: {
    total() {
      return parseInt(this.totalItems);
    },
  },
  mounted() {
    this.glide = new Glide('.news-carousel', {
      type: 'slider',
      // autoplay: 5000,
      gap: 8,
      perView: 4,
      rewind: true,
      breakpoints: {
        1200: {
          perView: 2,
        },
        800: {
          perView: 1,
        },
      },
    }).mount();
  },
  methods: {
    overide(i) {
      if (this.overideNumber === null) {
        this.overideNumber = i;
        const _this = this;
        window.setTimeout(() => {
          _this.overideNumber = null;
        }, 500);
      }
    },
  },
};
</script>
