<template>
  <div class="col" :class="[col, classes]">
    <div class="block block--toggle-list">
      <div class="block--content block__list">
        <div v-for="(item, index) in list" :key="`list-item__${index}`" class="block__list-item">
          <button
            class="item__header"
            :class="{ active: openToggle === index }"
            @click="toggleActive(index)"
          >
            <svg viewBox="0 -256 1792 2016" class="chev-bottom chevron-bottom">
              <path
                d="M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z"
                fill="currentColor"
              ></path>
            </svg>
            <h3>{{ item.title }}</h3>
          </button>

          <transition v-if="openToggle === index" name="fade">
            <div v-if="openToggle === index" class="item__content__container">
              <div class="item__content" v-html="item.text"></div>

              <a v-if="item.link && item.link.url" :href="item.link.url" class="item__link">
                <svg viewBox="0 -256 1792 2016" class="chevron-right chev-right">
                  <path
                    d="M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span>{{ item.link.text }}</span>
              </a>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleList',
  props: {
    info: {
      type: String,
      required: true,
    },
    col: {
      type: String,
      default: 'col-8',
    },
    classes: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      openToggle: -1,
    };
  },
  computed: {
    list() {
      return this.info ? JSON.parse(this.info) : null;
    },
  },
  methods: {
    toggleActive(e) {
      this.openToggle = e !== this.openToggle ? e : -1;
    },
  },
};
</script>
