<template>
  <div class="row">
    <div class="col-5">
      <div class="items">
        <div
          v-for="(item, index) in listItems"
          :key="index"
          class="benefit-list-item"
          :class="{ 'title-text': activeListItem === index }"
          @click="setActiveItem(index)"
        >
          <div class="list-item-content">
            <div class="content">
              <div class="list-item-title">
                <h3>{{ item.title }}</h3>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <path
                    fill="#004E90"
                    d="m16.0594 12-.5313.5313-4.9969 5-.5312.5312L8.9406 17l.5313-.5312L13.9406 12 9.4688 7.5313 8.9375 7 10 5.9407l.5312.5312 5 4.9969.5313.5312h-.0031Z"
                  />
                </svg>
              </div>
              <p v-if="activeListItem === index">{{ item.description }}</p>
            </div>
          </div>
          <div v-if="activeListItem === index" class="progress-bar">
            <div class="progress-bar-fill"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-7">
      <Lobby v-show="activeListItem === 0" :item="lobbyNews" />
      <Tools v-show="activeListItem === 1" :item="documentTool" />
      <Inspiration v-show="activeListItem === 2" :item="inspirationNews" />
      <Benefits v-show="activeListItem === 3" :item="memberBenefit" />
    </div>
  </div>
</template>

<script>
import Benefits from './BenefitBlocks/Benefits.vue';
import Inspiration from './BenefitBlocks/Inspiration.vue';
import Lobby from './BenefitBlocks/Lobby.vue';
import Tools from './BenefitBlocks/Tools.vue';
import ProgressAnimation from '../utils/progress-animation';

export default {
  name: 'BenefitsList',
  components: { Lobby, Benefits, Inspiration, Tools },
  props: {
    lobbyNews: {
      type: Object,
      default: () => ({}),
    },
    documentTool: {
      type: Object,
      default: () => ({}),
    },
    inspirationNews: {
      type: Object,
      default: () => ({}),
    },
    memberBenefit: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    progressAnimation: null,
    listItems: [
      {
        title: 'Belangenbehartiging voor de horeca',
        description:
          'We gebruiken onze lobbykracht om mee te denken, mee te praten, en op te komen voor de belangen van de horeca: lokaal, regionaal, nationaal én in Europa.',
      },
      {
        title: 'Horeca-experts helpen je snel verder',
        description:
          'Als lid van KHN ontvang je persoonlijk advies en krijg je antwoord op al je vragen. Hiervoor kun je terecht bij KHN Advies of bij je regiomanager.',
      },
      {
        title: 'Altijd op de hoogte',
        description:
          'Op de hoogte blijven van wat er speelt in de branche? Je krijgt van ons een wekelijkse update met het laatste horecanieuws, toegang tot Mijn KHN en KHN-events.',
      },
      {
        title: 'Gunstige prijzen en voorwaarden',
        description:
          'Onze partners zijn geselecteerd op gunstige prijzen en voorwaarden, maar ook op het juiste serviceniveau en ervaring met de horeca.',
      },
    ],
  }),
  computed: {
    activeListItem() {
      return this.progressAnimation?.activeListItem ?? 0;
    },
  },
  mounted() {
    this.progressAnimation = new ProgressAnimation(document.querySelectorAll('.benefit-list-item'));
    this.progressAnimation.start();
  },
  methods: {
    setActiveItem(index) {
      this.progressAnimation.stop();
      this.progressAnimation.progressPercentage = 0;
      this.progressAnimation.activeListItem = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text > .list-item-content > .content > .list-item-title {
  margin-top: 2.5rem;
}

.list-item-content {
  cursor: pointer;

  .content {
    .list-item-title {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.progress-bar {
  margin-top: 2.5rem;
  height: 2px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 9999px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #01508a;
  transition: all 0.1s;
}
</style>
