<template>
  <nav v-if="totalPages > 1" class="search-results__nav" aria-label="searchnavigation">
    <ol>
      <!-- Previous page [<<] -->
      <li v-if="totalPages > 1" class="search-results__nav-item search-results__prev-next-link">
        <button :disabled="!hasPreviousPage" @click="previousPage">&#60;&#60;</button>
      </li>
      <!-- First page [1...] -->
      <li v-if="currentPage > paginationSpread + 1" class="search-results__nav-item">
        <button @click="goToPage(1)">1 ...</button>
      </li>
      <!-- Previous pages [1 2 3 4] -->
      <li
        v-for="(page, index) of PreviousPageControls"
        :key="`prev-${index}`"
        class="search-results__nav-item"
      >
        <button @click="goToPage(page.value)">
          {{ page.label }}
        </button>
      </li>

      <!-- current [5] -->
      <li class="search-results__nav-item is-current-page">
        {{ currentPage }}
      </li>

      <!-- Next pages [6 7 8 9] -->
      <li
        v-for="(page, index) of NextPageControls"
        :key="`next-${index}`"
        class="search-results__nav-item"
      >
        <button @click="goToPage(page.value)">
          {{ page.label }}
        </button>
      </li>
      <!-- Last page [...24] -->
      <li v-if="currentPage < totalPages - paginationSpread" class="search-results__nav-item">
        <button @click="goToPage(totalPages)">... {{ totalPages }}</button>
      </li>
      <!-- Last page [>>] -->
      <li v-if="totalPages > 1" class="search-results__nav-item search-results__prev-next-link">
        <button :disabled="!hasNextPage" @click="nextPage">&#62;&#62;</button>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'NavPaginate',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paginationSpread: 3,
    };
  },
  computed: {
    currentPage() {
      return this.pagination.currentPage;
    },
    totalPages() {
      return this.pagination.totalPages;
    },
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    PreviousPageControls() {
      const data = [];
      let current = this.currentPage;
      while (current > 1 && data.length < this.paginationSpread) {
        current--;
        data.push({ label: `${current}`, value: current });
      }
      return data.reverse();
    },
    NextPageControls() {
      const data = [];
      let current = this.currentPage;
      while (current < this.totalPages && data.length < this.paginationSpread) {
        current++;
        data.push({ label: `${current}`, value: current });
      }
      return data;
    },
  },
  methods: {
    goToPage(e) {
      this.$emit('changePage', e);
    },
    nextPage() {
      if (this.hasNextPage) {
        this.goToPage(parseInt(this.currentPage) + 1);
      }
    },
    previousPage() {
      if (this.hasPreviousPage) {
        this.goToPage(parseInt(this.currentPage) - 1);
      }
    },
  },
};
</script>
