import { render, staticRenderFns } from "./AccomplishmentCarousel.vue?vue&type=template&id=5dd7f56d"
import script from "./AccomplishmentCarousel.vue?vue&type=script&lang=js"
export * from "./AccomplishmentCarousel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports