<template>
  <div class="search-results-items">
    <div v-if="!loading">
      <template v-if="parsedTerm !== ''">
        <div class="search-result__tab-container">
          <!-- Navigation -->
          <button
            :class="['search-result__tab', { active: query.mode === 'page' }]"
            @click="changeMode('page')"
          >
            <span v-if="pageResultsCount === 0"> ({{ pageResultsCount }}) Zoekresultaat </span>
            <span v-else-if="pageResultsCount === 1"> ({{ pageResultsCount }}) Zoekresultaat </span>
            <span v-else>({{ pageResultsCount }}) Zoekresultaten</span>
          </button>
          <button
            :class="['search-result__tab', { active: query.mode === 'faq' }]"
            @click="changeMode('faq')"
          >
            <span v-if="faqResultsCount === 0"> ({{ faqResultsCount }}) Veelgestelde vragen </span>
            <span v-else-if="faqResultsCount === 1">
              ({{ faqResultsCount }}) Veelgestelde vraag
            </span>
            <span v-else>({{ faqResultsCount }}) Veelgestelde vragen</span>
          </button>
        </div>

        <div v-if="currentResults && currentResults.length > 0">
          <!-- Search results -->
          <ul class="search-results__list">
            <li v-for="(item, index) in currentResults" :key="`list-item--${index}`">
              <search-item :item="item" :search-mode="query.mode"></search-item>
            </li>
          </ul>

          <!-- Pagination -->
          <nav-paginate
            v-if="currentModePagination"
            :pagination="currentModePagination"
            @changePage="changePage"
          ></nav-paginate>
        </div>

        <!-- Search Fallback -->
        <div v-else>
          <p>
            Je zoekterm <strong>{{ parsedTerm }}</strong> leverde geen resultaten op.
          </p>
          <p>Suggesties:</p>
          <ul>
            <li>Zorg ervoor dat alle woorden goed gespeld zijn</li>
            <li>Probeer andere zoektermen</li>
            <li>Probeer meer algemene zoektermen</li>
            <li>Probeer minder zoektermen</li>
          </ul>
        </div>
      </template>

      <p v-else class="search-results-items__none-warn">
        Begin met zoeken door een zoekterm in te vullen.
      </p>
    </div>

    <!-- Loading state -->
    <div v-if="loading" class="search-results__spinner-container">
      <div class="spinner-wrap">
        <loading-state></loading-state>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    parsedTerm: {
      type: String,
      default: null,
    },
    parsedPage: {
      type: Number,
      default: 1,
    },
    parsedMode: {
      type: String,
      default: 'page',
    },
  },
  data() {
    return {
      loading: false,
      query: {
        term: null,
        page: 1,
        mode: 'page',
      },
      results: {
        page: [],
        faq: [],
      },
    };
  },
  computed: {
    pageResultsCount() {
      return this.results.page ? this.results.page.nbSortedHits : 0;
    },
    faqResultsCount() {
      return this.results.faq ? this.results.faq.nbHits : 0;
    },
    currentResults() {
      return this.results[this.query.mode] && this.results[this.query.mode].hits
        ? this.results[this.query.mode].hits
        : [];
    },
    currentModePagination() {
      return this.results[this.query.mode]
        ? {
            firstPage: 1,
            currentPage: this.results[this.query.mode].page + 1,
            totalPages: this.results[this.query.mode].nbPages,
          }
        : { firstPage: 1, currentPage: 1, totalPages: 1 };
    },
  },
  watch: {
    parsedTerm() {
      if (!this.loading && this.parsedTerm !== this.query.term) {
        this.query = {
          term: this.parsedTerm,
          page: 1,
          mode: this.query.mode,
        };
        this.getQueryResults();
      }
    },
    parsedPage() {
      if (!this.loading && this.parsedPage !== this.query.page) {
        this.query.page = this.parsedPage;
        this.getQueryResults();
      }
    },
    parsedMode() {
      if (!this.loading && this.parsedMode !== this.query.mode) {
        this.query = {
          term: this.query.term,
          page: this.query.page,
          mode: this.parsedMode,
        };
        if (this.query.page !== 1) {
          this.query.page = 1;
          this.changePage(1);
          this.getQueryResults();
        }
      }
    },
  },
  mounted() {
    this.loading = true;
    this.$nextTick(() => {
      this.query = {
        term: this.parsedTerm,
        page: this.parsedPage,
        mode: this.parsedMode,
      };
      this.loading = false;
      this.getQueryResults();
    });
  },
  methods: {
    changeMode(e) {
      this.$emit('changeMode', e);
    },
    changePage(e) {
      this.$emit('changePage', e);
    },

    // Query results
    getQueryResults() {
      if (this.query.term && this.query.page >= 1 && this.query.mode) {
        this.loading = true;
        const self = this;

        axios
          .post('/actions/algolia/default/multiple-queries', {
            queries: [
              {
                indexName: 'page_postdate_desc',
                query: self.query.term,
                params: {
                  hitsPerPage: 10,
                  page: self.query.page - 1,
                },
              },
              {
                indexName: 'faq',
                query: self.query.term,
                params: {
                  hitsPerPage: 10,
                  page: self.query.page - 1,
                },
              },
            ],
          })
          .then(function (response) {
            if (!response.data.results) {
              return;
            }

            const pageResults = response.data.results[0];
            const faqResults = response.data.results[1];

            self.results = {
              page: pageResults,
              faq: faqResults,
            };
            self.loading = false;
          })
          .catch(function () {
            self.loading = false;
          });
      }
    },
  },
};
</script>
