<template>
  <div v-if="showPopup && info" class="modal-holder container vue-popup">
    <div class="row">
      <div class="modal popup">
        <div v-if="info.img" class="modal--image">
          <LozadImage :img="info.img" />
        </div>
        <div class="modal--bottom">
          <h2 v-text="info.title"></h2>
          <div v-html="info.content"></div>
          <span v-for="(cta, index) in info.ctas" :key="index" class="cta">
            <a :href="cta.url">{{ cta.title }}</a>
          </span>
        </div>
        <button class="modal--close" @click="setLocalStorageVariable(true)">
          <i class="fas fa-icon fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LozadImage from './LozadImage.vue';
export default {
  name: 'PopUp',
  components: {
    LozadImage,
  },
  props: {
    dataString: { type: String, required: true },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    info() {
      return JSON.parse(this.dataString);
    },
  },
  mounted() {
    this.getPopupState();
  },
  methods: {
    getPopupState() {
      if (this.checkIfNewBannerId() && this.info.startDate && this.info.endDate) {
        const currentDate = Math.floor(Date.now() / 1000);
        const startDate = this.info.startDate;
        const endDate = this.info.endDate;
        this.toggle(currentDate >= startDate && currentDate <= endDate);
      }
    },
    checkIfNewBannerId() {
      return !window.localStorage.getItem(this.info.id);
    },
    setLocalStorageVariable(value) {
      window.localStorage.setItem(this.info.id, value);
      this.toggle(!value);
    },
    toggle(value) {
      this.showPopup = value;
    },
  },
};
</script>
