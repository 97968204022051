<template>
  <div class="lobby-news">
    <a href="/nieuws/lobby-nieuws" class="link">
      Bekijk al het lobby nieuws
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
          fill="#004E90"
          d="m16.0594 12-.5313.5313-4.9969 5-.5312.5312L8.9406 17l.5313-.5312L13.9406 12 9.4688 7.5313 8.9375 7 10 5.9407l.5312.5312 5 4.9969.5313.5312h-.0031Z"
        />
      </svg>
    </a>
    <div class="text">
      We gebruiken onze lobbykracht om mee te denken, mee te praten, en op te komen voor de belangen
      van de horeca: lokaal, regionaal, nationaal én in Europa.
    </div>
    <a :href="item.url" class="link-card">
      <img :src="item.image" :alt="item.title" />
      <div class="content">
        <div class="date">{{ item.postDate }}</div>
        <div class="title">{{ item.title }}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
