<template>
  <section class="container">
    <div class="navigation-wrapper">
      <!-- Navigation Top -->
      <div class="navigation-container">
        <a href="/" target="_self">
          <img alt="KHN Logo" class="navigation-logo" src="/assets/logos/khn_logo.svg" />
        </a>

        <div class="navigation-top">
          <button type="button" class="search-button" @click="searchFormOpen = true">
            <span class="search-icon">
              <Search />
            </span>
            <span> Zoek direct naar... </span>
          </button>
          <div :class="{ 'search-form-open': searchFormOpen }" class="search-form">
            <form method="get" action="/zoeken">
              <div class="search-wrapper">
                <input type="search" name="term" placeholder="Zoek direct naar..." />
                <div class="search-icon"></div>
              </div>
            </form>
            <button type="button" class="search-form-close" @click="searchFormOpen = false">
              <XMark />
            </button>
          </div>

          <ul v-if="menu.externalLinks" class="navigation-list">
            <li
              v-for="(externalLink, index) in menu.externalLinks"
              :key="index"
              :class="{ 'navigation-item': true, 't-navigation-small': !externalLink.redButton }"
            >
              <a
                :href="externalLink.url"
                :class="{ 'button-primary': externalLink.redButton }"
                target="_blank"
                >{{ externalLink.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- Navigation Bottom -->
      <div class="navigation-container">
        <ul class="navigation-list">
          <li class="navigation-item t-navigation">
            <a :href="menu.overviewPage.url">
              {{ menu.overviewPage.title }}
            </a>
          </li>
          <li v-for="item in menu.mainNav" :key="item.title" class="navigation-item t-navigation">
            <a :href="item.url">{{ item.title }}</a>
          </li>
        </ul>

        <ul class="navigation-list">
          <li v-for="item in menu.subNav" :key="item.title" class="navigation-item t-navigation">
            <a :href="item.url" class="t-normal">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Search from '@/components/icons/Search.vue';
import XMark from '@/components/icons/XMark.vue';

export default {
  name: 'NavigationDesktop',
  components: {
    Search,
    XMark,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    searchFormOpen: false,
  }),
};
</script>
