<template>
  <div class="lobby-news">
    <a href="/advies" class="link">
      Neem contact op met KHN Advies
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
          fill="#004E90"
          d="m16.0594 12-.5313.5313-4.9969 5-.5312.5312L8.9406 17l.5313-.5312L13.9406 12 9.4688 7.5313 8.9375 7 10 5.9407l.5312.5312 5 4.9969.5313.5312h-.0031Z"
        />
      </svg>
    </a>
    <div class="text">
      Als lid van KHN ontvang je persoonlijk advies en krijg je antwoord op al je vragen.
      Bijvoorbeeld over arbeidsvoorwaarden en wet- en regelgeving. Hiervoor kun je terecht bij KHN
      Advies of bij je regiomanager.
    </div>
    <a :href="item.url" class="link-card">
      <img :src="itemImage" :alt="item.title" />
      <div class="content">
        <div class="title">{{ item.title }}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    itemImage() {
      return this.item.image.length
        ? this.item.image
        : '/assets/pages/_overeenkomst_contract_tekenen.jpg';
    },
  },
};
</script>
