export default class ProgressAnimation {
  constructor(listItems) {
    this.listItems = listItems;
    this.progressPercentage = 0;
    this.activeListItem = 0;
    this.lastTimestamp = 0;
    this.isRunning = false;

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
  }

  start() {
    this.isRunning = true;
    this.animate();
  }

  stop() {
    this.isRunning = false;
  }

  animate(timestamp) {
    if (!this.isRunning) return;

    if (!this.lastTimestamp) this.lastTimestamp = timestamp;
    const elapsed = timestamp - this.lastTimestamp;

    if (elapsed > 15) {
      this.lastTimestamp = timestamp;
      this.updateProgress();
    }

    requestAnimationFrame(this.animate);
  }

  updateProgress() {
    const newPercentage = this.progressPercentage >= 100 ? 0 : this.progressPercentage + 0.3;

    if (newPercentage === 0) {
      this.activeListItem =
        this.activeListItem === this.listItems.length - 1 ? 0 : this.activeListItem + 1;
    }

    this.progressPercentage = newPercentage;

    // Here you would update your UI with the new progress and active list item
    this.updateUI();
  }

  updateUI() {
    const progressBarForActiveItem = document.querySelector('.title-text .progress-bar');
    const innerProgressBar = progressBarForActiveItem.querySelector('.progress-bar-fill');

    innerProgressBar.style.width = `${this.progressPercentage}%`;
  }
}
