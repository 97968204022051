/* eslint-disable global-require */
<template>
  <nav v-if="navigation" class="navigation" :class="{ 'is-scrolled': isScrolled }">
    <NavigationDesktop :menu="navigation" class="navigation-desktop" />
    <NavigationMobile :menu="navigation" class="navigation-mobile" />
  </nav>
</template>

<script>
import NavigationDesktop from './navigation/NavigationDesktop.vue';
import NavigationMobile from './navigation/NavigationMobile.vue';

export default {
  name: 'NavMenu',
  components: { NavigationDesktop, NavigationMobile },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isScrolled: true,
    };
  },
  mounted() {
    this.onScroll();
    document.body.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.isScrolled = document.body.scrollTop > 50;
    },
  },
};
</script>
