<template>
  <div ref="navigationModal" class="navigation-modal container wrapper">
    <div class="navigation-content">
      <ul class="navigation-list">
        <li class="navigation-item t-navigation">
          <a :href="menu.overviewPage.url">{{ menu.overviewPage.title }}</a>
        </li>
        <li v-for="item in menu.mainNav" :key="item.title" class="navigation-item t-navigation">
          <a :href="item.url">{{ item.title }}</a>
        </li>
      </ul>
      <ul class="navigation-list">
        <li v-for="item in menu.subNav" :key="item.title" class="navigation-item t-navigation">
          <a :href="item.url" class="t-normal">{{ item.title }}</a>
        </li>
      </ul>
    </div>

    <div class="navigation-bottom">
      <ul v-if="menu.externalLinks" class="navigation-list">
        <li
          v-for="(externalLink, index) in menu.externalLinks.slice().reverse()"
          :key="index"
          :class="{
            'navigation-item': !externalLink.redButton,
            'navigation-button': externalLink.redButton,
          }"
        >
          <a
            :href="externalLink.url"
            :class="{ 'button-primary': externalLink.redButton }"
            target="_blank"
            >{{ externalLink.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'NavigationModal',
  props: {
    menu: {
      type: Object,
      required: true,
    },
    isNavigationOpen: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isNavigationOpen() {
      if (this.isNavigationOpen) {
        this.handleNavigationModalToggle(1);
        return;
      }

      this.handleNavigationModalToggle(-1);
    },
  },
  mounted() {
    this.initNavigationModal();
  },
  methods: {
    initNavigationModal() {
      gsap.set(this.$refs.navigationModal, {
        yPercent: -100,
      });
    },
    handleNavigationModalToggle(direction) {
      gsap.to(this.$refs.navigationModal, {
        duration: 0.7,
        yPercent: direction < 0 ? -100 : 0,
        ease: 'power2.out',
      });
    },
  },
};
</script>
